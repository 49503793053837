import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrder() {
  // Use toast
  const toast = useToast()
  const contactType = computed(() => store.state.auth.contactType);
  const refOrdersListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'order_number', label: '#', sortable: true },
    { key: 'order_date', sortable: true },
    { key: 'item_count', sortable: true },
    { key: 'grand_total', sortable: true },
    //{ key: 'order_status', sortable: true },
    { key: 'payment_status', sortable: true },
    { key: 'status', sortable: true },
    // { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'actions' },
  ]

  if (contactType.value == "supplier") {
    tableColumns.value = [
      { key: 'order_number', label: '#', sortable: true },
      { key: 'order_date', sortable: true },
      { key: 'contact_name', sortable: true },
      { key: 'item_count', sortable: true },
      { key: 'grand_total', sortable: true },
      //{ key: 'order_status', sortable: true },
      { key: 'payment_status', sortable: true },
      { key: 'status', sortable: true },
      // { key: 'total', sortable: true, formatter: val => `$${val}` },
      { key: 'actions' },
    ]
  }
  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrdersListTable.value ? refOrdersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    refOrdersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchOrders = (ctx, callback) => {
    store
      .dispatch('orders/fetchOrders', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalOrders.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching orders' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrderStatusVariantAndIcon = status => {
    if (status === false || status === 0) return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === true || status === 1) return { variant: 'success', icon: 'CheckCircleIcon' }
    // if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    // if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    // if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    // if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === false || status === 0) return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === true || status === 1) return 'success'
    return 'primary'
  }
  const resolveOrderStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'completed') return 'success'
    if (status === 'canceled') return 'secondary'
    return 'primary'
  }

  const resolvePaymentStatusVariant = status => {
    if (status === 'unpaid') return 'warning'
    if (status === 'paid') return 'success'
    return 'primary'
  }
  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrdersListTable,

    statusFilter,
    resolveOrderStatusVariantAndIcon,
    resolveClientAvatarVariant,
    resolvePaymentStatusVariant,
    resolveOrderStatusVariant,
    refetchData,
  }
}
